import dayjs from 'dayjs'
import type { Dayjs as DayjsType } from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import 'dayjs/locale/ru'


dayjs.extend(localeData)
dayjs.extend(localizedFormat)
dayjs.locale('ru')
dayjs.extend(customParseFormat)
dayjs.extend(duration)

export type Dayjs = DayjsType

export { default as formatDateRange } from './formatDateRange'

export default dayjs
